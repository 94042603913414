@import "../../../styles/scss-variable.scss";
calendar-cell:nth-child(7n){
  border-right: 1px solid $light-gray;
}
.calendar-cell {
  height: 50px;
  cursor: pointer;
  min-width: 6%;
  width: 15%;
  border-left: 1px solid $light-gray;
  border-bottom: 1px solid $light-gray;
  position: relative;
  margin-right: -1px;
  margin-top: -1px;

  .user-name-text{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .claim {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    color: $primary-color;
    border-radius: $border-radius;

    img {
      margin-right: 5px;
    }
  }

  .cell-center {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .appointment {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 98%;
    height: 50px;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    margin-left: 5px;

    img {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      background-color: white;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      margin-right: 8px;
      object-fit: cover;
    }

    .name-avatar {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      background-color: white;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      margin-right: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: bold;
    }

    .join {
      margin-left: 2px;
      font-size: 16px;
      height: 32px;
      max-width: 60%;
      width: 100%;
    }
  }
}



.calendar-cell-active {
  background-color: rgba(221, 221, 221, 0.30);
  border-left: 1px solid $primary-color;
  border-right: 1px solid $primary-color;
  z-index: 1;
}

@media screen and (max-width: 700px) {

  .calendar-cell{
    width: 90%;
  }

  .calendar-cell-active{
    width: 90%;
  }
}

@media screen and (max-width: 1000px) {

  .calendar-cell{
    width: 100%;
  }

  .calendar-cell-active{
    width: 100%;
  }
}