@import "/src/styles/scss-variable.scss";

.popup-intro{
    display: flex;
    position: fixed;
    flex-direction: column;
    overflow-y: auto;
    width: min-content;
    height: min-content;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: $white;
    border-radius: 8px;
    align-items: center;
    min-width: 550px;
    .column-intro{
        display: flex;
        flex-direction: column;
        padding-right: 40px;
        padding-left: 40px;
        padding-bottom: 40px;
        padding-top: 40px;
        align-items: center;
        
        .accept-intro-button {
            align-self: center;
            width: 359px;
            height: 50px;
            font-size: 16;
          }
    }
    
.description-text{
 font-size: 18px;
 padding-top: 30px;
 padding-bottom:30px;

}
.tight-text{
    font-size: 18px;
    padding-top :3px;
    padding-bottom : 3px;
}
}

.booking-image {
    max-width: 650px;
    aspect-ratio: auto;
}


@media screen and (max-width: 700px){
    .popup-intro{
        height: 30%;
        width: 50%;
        overflow: hidden;
    }
    .popup-intro .column-intro{
        padding-left: 10%;
        padding-right: 10%;
        padding-bottom: 10%;
        overflow: hidden;
    }

    .popup-intro .column-intro .accept-intro-button{
        width: 100%;
        font-size: 14px;
    }
}
