@import "../../../styles/scss-variable.scss";

.home-icon-row {
  top: 40px;
  left: 40px;
  position: absolute;
  cursor: pointer;

  .home-icon {
    height: 25px;
    width: 25px;
    object-fit: contain;
    filter: invert(46%) sepia(92%) saturate(408%) hue-rotate(94deg) brightness(97%) contrast(100%);  
  }

  .home-label{
    color: $white !important;
  }
}

@media screen and (max-height: 690px) {
  .home-icon-row{
    top: 10px;
    left: 10px;
  }
}

@media screen and (max-height: 645px) {
  .home-icon-row .home-label{
    color: transparent !important;
  }
}
