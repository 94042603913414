@import "../../../styles/scss-variable.scss";

.card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  height: 165px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  padding: 10px;
  background-color: $white;

  .top-text{
    align-items: center;
    height: 70px;

    .date {
      color: $black;
      font-weight: 300;
      font-size: 16px;
      text-align: center;
    }
  
    .time {
      text-align: center;
      color: $primary-color;
    }  
  }

  
  .action-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .cancel-button {
      cursor: pointer;
      height: 38px;
      width: 38px;
      min-width: 38px;
      border-radius: 50%;
      display: flex;
      background-color: transparent;
      justify-content: center;
      align-items: center;

      .cancel-icon {
        height: 18px;
        width: 18px;
        object-fit: contain;
      }
    }

    .join-button {
      height: 38px;
    }
  }
    
    .calendar-button {
      height: 30px;
      background-color: $white;
      border-radius: $border-radius;
      border: none;
      color: $primary-color;
      font-weight: bold;
      cursor: pointer;
      padding: 5px;
      width: 110%;
      text-decoration: unset;
      font-size: 14px;

      .calendar-text {
        text-decoration: unset;
        color: $primary-color;
        width: 100%;
        height: 100%;
        text-align: center;
      }
    }
}

.hover-card-container{
  display: flex;
  flex-direction: column;
  width: 200px;
  height: 165px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  padding: 10px;
  background-color: $white;
  align-items: center;

  .hover-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 70px;
  }

  .action-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .cancel-button {
      cursor: pointer;
      height: 38px;
      width: 38px;
      min-width: 38px;
      border-radius: 50%;
      display: flex;
      background-color: transparent;
      justify-content: center;
      align-items: center;

      .cancel-icon {
        height: 18px;
        width: 18px;
        object-fit: contain;
      }
    }

    .join-button {
      height: 38px;
    }
  }

  .hover-text{
    font-size: 16px;
    color: $primary-color;
  }

  .calendar-button {
    height: 30px;
    background-color: $white;
    border-radius: $border-radius;
    border: none;
    color: $primary-color;
    font-weight: bold;
    cursor: pointer;
    padding: 5px;
    width: 110%;
    text-decoration: unset;
    font-size: 14px;

    .calendar-text {
      text-decoration: unset;
      color: $primary-color;
      width: 100%;
      height: 100%;
      text-align: center;
    }
  }
}

