@import "../../../../../styles/scss-variable.scss";

.youtube-container{
  height: calc(100vh - 60px);
  position: relative;
  z-index: -1;

  .transparent-box{
    height: calc(100vh - 60px);
    position: relative;
  }

  .countdown-component {
    position: absolute;
    top: 20px;
    left: 20px;
  }
}

.youtube-container-mobile{
  height: calc(100vh - 60px);
  position: relative;

  .transparent-box{
    height: calc(100vh - 60px);
    position: relative;
  }

  .countdown-component {
    position: absolute;
    top: 20px;
    left: 20px;
  }
}

.option-container {
    height: calc(100vh - 60px);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .text-container{
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: 50px;
      margin-right: 20px;
    }

    .info-text {
      font-size: 22px;
      color: $primary-color;
    }

    .option-header {
      color: $primary-color;
      font-size: 30px;
    }

    .button-row {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      padding-left: 40px;
      padding-right: 40px;

      .option-button {
        height: 200px;
        width: 25%;
        font-size: 48px;
      }

      .button-text {
        font-size: 20px;
      }
    }
  }

  .finish-container{
    margin-left: 20%;
    margin-right: 20%;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .finish-header{
      color: $primary-color;
      font-size: 30px;
    }

    .finish-text{
      font-size: 18px;
      margin-bottom: 10px;
    }

    .finish-button{
      margin-top: 30px;
      width: 250px;
    }
  }

  @media screen and (max-width: 630px) {
    .option-container.button-row.option-button {
      font-size: 24px;
      height: 120px;
    }
  }
  
  
  @media screen and (max-width: 400px) {
    .option-container .button-row .option-button {
      font-size: 20px;
      height: 110px;
      padding-left: 5px;
      padding-right: 5px;
    }

    .option-container .button-row .button-text {
      font-size: 14px;
    }
  }

  @media screen and (max-width: 250px) {
    .option-container .button-row .option-button {
      font-size: 16px;
      height: 100px;
      padding-left: 5px;
      padding-right: 5px;
    }
  }