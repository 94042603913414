.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
   color: red;
}

.MuiOutlinedInput-root.Mui-focused{
    border-color: red;
}

.MuiInputLabel-outlined {
    color: red;
}