@import "/src/styles/scss-variable.scss";

.popup-rematching {
  display: flex;
  position: fixed;
  flex-direction: column;
  overflow-y: auto;
  width: 400px;
  height: min-content;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: $white;
  border-radius: 8px;
  align-items: center;
  justify-content: space-between;
  padding: 20px;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: center;
    height: 100%;
  }
}

@media screen and (max-width: 400px) {
  .popup-rematching {
    width: 90%;
  }
}
