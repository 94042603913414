@import "../../../../styles/scss-variable.scss";


.border-right {
  border-right: 1px solid $light-gray;
}

.background-white{
   background-color: white;
   width: 100%;
   min-width: 5%;
}

.header-cell {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 100%;
  border-bottom: 1px solid $light-gray;
  padding-bottom: 20px;
  position: relative;
  margin-right: -1px;
  padding-top: 15px;
  min-width: 100%;

  .line-left {
    border-left: 1px solid $light-gray;
    position: absolute;
    height: 60%;
    left: 0;
    bottom: 0;
  }

  .line-right {
    border-right: 1px solid $light-gray;
    position: absolute;
    height: 60%;
    right: 0;
    bottom: 0;
  }

  .line-active {
    border-left: 1px solid $primary-color;
    z-index: 99;
  }

  .line-right-active {
    border-left: 1px solid $primary-color;
    right: 10;
    z-index: 99;
  }

  .date {
    color: $black;
    font-weight: bold;
    font-size: 32px;
  }
  .day {
    color: $black;
    font-size: 24px;
  }

  .text-active {
    color: $primary-color;
  }
}

@media screen and (max-width: 700px) {
  .header-cell {
    width: 90%;
  }

  .background-white{
    width: 100%;
  }
}

.cell-active {
  background-color: rgba(221, 221, 211, 0.3);
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
}