@import "../../../../styles/scss-variable.scss";

.homescreen {
  width: 100%;
  height: 100%;
  background-color: $white;
  display: flex;
  flex-direction: row;
  
  @media screen and (min-width: 700px){
    .calendar{
      width: 85%;
      overflow: auto;
    }
  }
}

@media screen and (max-width: 700px){
  .homescreen{
    flex-direction: column;
    overflow: auto;
  }

  .calendar{
    width: 100%;
  }
}
