@import "../../../../styles/scss-variable.scss";
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap');

.default-text {
  font-size: $text-font-size;
  color: $black;
  font-family: 'Space Grotesk', sans-serif;
  font-weight: 400;
}

.scaled-text { //used to wrap text that is being scaled/colored by a different style sheet
  font-family: 'Space Grotesk', sans-serif;
  font-weight: 400;
}