@import "../../../../styles/scss-variable.scss";

.login-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-height: 750px) {
    .login-container {
        display: flex;
        align-items: center;
        padding-bottom: 10px;
    }
}