
@import "/src/styles/scss-variable.scss";

.sidebar{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

        .session-column{
            display: flex;
            flex-direction: column;
            width: 220px;
            flex-grow: 1;
            align-items: center;
            margin-left: 20px;
            overflow-x: hidden;
            overflow-y: auto;
            padding-bottom: 10px;
            max-height: 75%;

            .description-box{
                background-color: #ebebeb;
                width: 220px;
                border-radius: 10px;
                padding-left: 8px;
                padding-right: 8px;
                padding-top: 8px;
                padding-bottom: 8px;
            }
        }


        .session-text{
            align-self: flex-start;
            font-size: 16px;
            padding-left: 30px;
            font-weight: bold;
        }
    
    .info-text-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 40px;
        margin-top: 5px;

        .guidelines-link{
            color: $primary-color;
            font-size: 16px;
            margin-top: 10px;
            cursor: pointer;
        }
        
        .info-text{
            font-size: 12px;
            cursor: pointer;
        }

        .small-text{
            font-size: 12px;
        }
    }
    .mt-15{
        margin-top: 15px;
    }
}

@media screen and (min-width: 700px) {
    .card-container2{
        padding-top: 20px;
    }
}

@media screen and (max-width: 700px) {
    .sidebar{
        flex: block;
    }

    .sidebar .session-column{
        flex-direction: row;
        width: 100%;
        overflow-x: auto;
        padding-bottom: 10px;
        margin-left: 30px;
        padding-left: 10px;
        padding-top: 20px;
        overflow-y: hidden;
        max-height: 100%;
    }

    .sidebar .card-row{
        display: flex;
        flex-direction: row;
        padding-right: 20px;
        padding-top: 30px;
    }

    .card-container2{
        padding-left: 20px;
    }
}

@media screen and (max-height: 840px) {
    .sidebar .info-text-container{
        padding-bottom: 60px;
    }
}