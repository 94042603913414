@import "/src/styles/scss-variable.scss";

.popup-opponent {
  display: flex;
  position: fixed;
  min-width: 45%;
  width: fit-content;
  max-width: 70%;
  height: min-content;
  left: 0;
  right: 0;
  bottom: 70px;
  margin: auto;
  background-color: $white;
  border-radius: 8px;
  justify-content: space-around;

  .popup-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; 
    margin: 30px;

    .cancel-icon {
        width: 12.33;
        height: 12.33;
        align-self: flex-end;
        cursor: pointer;
      }

      .avatar-container {
        border-radius: 50%;
    
        img {
          width: 100px;
          height: 100px;
          object-fit: cover;
          cursor: pointer;
          border-radius: 50%;
        }
    
        .avatar {
          width: 100px;
          height: 100px;
          cursor: pointer;
          border-radius: 50%;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }
    
        .avatar-text {
          background-color: $white;
          cursor: pointer;
          border-radius: 50%;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          font-size: 40px;
          font-weight: bold;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100px;
          height: 100px;
        }
      }
  }

  .avatar {
    object-fit: cover;
    width: 96px;
    height: 96px;
  }

  .username {
    font-weight: 600;
    font-size: 16px;
  }

  .session-text {
    font-style: italic;
    font-size: 14px;
    font-weight: normal;
    color: $gray;
  }

  .task-text-mobile{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    align-self: center;
    text-align: center;
  }

  .header {
    font-size: 16px;
  }

  .task-text {
    font-size: 15px;
  }

  .button-row {
    display: flex;
    flex-direction: row;
    width: 100%;

    .decline-button {
      background: $gray;
    }
  }
}

@media screen and (max-width: 1000px) {
  .popup-attendance .popup-column {
    overflow-y: auto;
  }

  .popup-opponent{
    width: 60%;
  }
}

@media screen and (max-width: 700px) {
  .popup-opponent{
    width: 80%;
  }
}

@media screen and (max-width: 450px) {
  .popup-opponent{
    width: 90%;
  }
}
