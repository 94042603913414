@import "/src/styles/scss-variable.scss";

.sign-up-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: $default-form-width;
    position: fixed;
    min-width: 25%;
    height: min-content;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: $white;
    border-radius: 8px;
    padding: 0px;
    padding-bottom: 10px;
  }

  .input-content {
    display: flex;
    flex-direction: column;
      
    .refer-button {
      align-self: flex-end;
    }
  }
  .button-row{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    padding-bottom: 30px;
    padding-top: 20px;
    align-self: center;
    align-content: center;
    bottom: 0;
    .invite-button{
        width: 250px;
        margin: 0 auto;
    }

    .close-button{
        width: 250px;
        height: 50px;
        background-color: $gray;
        margin: 0 auto
    }
}

.user-list{
  display:block;
    list-style-type: none;
    float:left;
    height: 350px;
    width:100%;
    overflow-y:auto;
}
.no-users-text{
  font-weight: 600;
  font-size: 16px;
  color:$light-gray;
}
.user-line{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    float:left;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    .avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
      }
    
      .avatar-text {
        border-radius: 50%;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-size: 20px;
        font-weight: bold;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        float:left;
      }
    
      .username {
        font-weight: 600;
        font-size: 16px;
        margin:0 auto;
        margin-left: 15%;
      }

      .cancel-button {
        cursor: pointer;
        height: 38px;
        width: 38px;
        min-width: 38px;
        border-radius: 50%;
        display: flex;
        background-color: transparent;
        justify-content: center;
        align-items: center;
        float: right;
        .cancel-icon {
          height: 38px;
          width: 38px;
          object-fit: contain;
        }
      }
    }
   
@media screen and (max-width: 800px){
    .popup-referal .popup-column .referral-image {
        width: 200px;
    }
}

@media screen and (max-width: 600px){
    .popup-referal  {
        width: 80%;
    }
}

@media screen and (max-width: 460px){
    .popup-referal .popup-column .text  {
        font-size: 12px;
    }
}

@media screen and (max-width: 350px){
    .popup-referal .popup-column .referral-image {
        width: 150px;
    }

    .popup-referal .popup-column {
        align-items: flex-start;
        overflow: auto;
    }
}