@import "/src/styles/scss-variable.scss";

.popup-warning{
    display: flex;
    position: fixed;
    flex-direction: column;
    overflow-y: auto;
    width: min-content;
    height: min-content;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: $white;
    border-radius: 8px;
    align-items: center;

    .column-warning{
        display: flex;
        flex-direction: column;
        padding-right: 40px;
        padding-left: 40px;
        padding-bottom: 40px;
        padding-top: 40px;
        align-items: center;
        
        .accept-warning-button {
            align-self: center;
            width: 359px;
            height: 50px;
            font-size: 16;
          }
    }
}

@media screen and (max-width: 700px){
    .popup-warning{
        height: 30%;
        width: 50%;
        overflow: hidden;
    }
    .popup-warning .column-warning{
        padding-left: 10%;
        padding-right: 10%;
        padding-bottom: 10%;
        overflow: hidden;
    }

    .popup-warning .column-warning .accept-warning-button{
        width: 100%;
        font-size: 14px;
    }
}
