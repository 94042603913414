@import "../../../styles/scss-variable.scss";

.countdown-container {
  height: 75px;
  width: 181px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $gray;
  border-radius: 10px;

  .countdown-label {
    color: $white;
  }
}
