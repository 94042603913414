@import "../../../styles/scss-variable.scss";

.bg-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(180deg, #F3FFF9 0%, rgba(243, 255, 249, 0) 100%);
}
.wave {
  width: 100%;
}

@media only screen and (max-width: 860px) {
  .wave {
    right: 0;
    min-height: 100%;
  
    width: auto;
    height: auto;
    margin: 0;
    padding: 0;
  }
}

.front-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
