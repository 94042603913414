@import "../../../../../../styles/scss-variable.scss";

.reset-password-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: $default-form-width;

  .logo {
    height: 120px;
    width: 260px;
    object-fit: cover;
  }

  .input-content {
    display: flex;
    flex-direction: column;
  }

  .forget-password-button {
    align-self: flex-end;
  }
}
