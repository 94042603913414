// Colors
$primary-color: #0db358;
$white: #ffffff;
$black: #333333;
$gray: #bdbdbd;
$light-gray: #999999;
$red: #EB5757;

// Font-sizes
$header-font-size: 24px;
$text-font-size: 14px;

// Variables
$container-default-width: 1500px;
$default-card-min-heigth: 600px;
$default-form-width: 510px;
$default_padding: 30px;
$border-radius: 12px;
$navbar-height: 60px;
