@import "/src/styles/scss-variable.scss";

.popup-profile {
  display: flex;
  position: fixed;
  overflow-y: auto;
  width: 80%;
  height: 90%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: $white;
  border-radius: 8px;

  .popup-column {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: min-content;
    margin: auto;
    padding-right: 25.84px;
    padding-top: 19.83px;
    padding-left: 30px;
    padding-bottom: 30px;
    align-items: flex-start;

    .logout{
      width: 100%;
      cursor: pointer;
      text-align: center;
      margin-top: 25px;
    }

    .cancel-icon {
      width: 12.33;
      height: 12.33;
      align-self: flex-end;
      cursor: pointer;
    }

    .popup-row {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      width: 100%;
      height: 100%;
      padding-bottom: 30px;
      padding-left: 50px;
      padding-right: 52px;
      padding-top: 27px;

      .left-column {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        align-items: center;
        padding-right: 52px;

        .textfield{
          width: 100%;
        }

        .dropdown {
          z-index: 1;
        }

        .switch-style {
          display: flex;
          width: 100%;
          justify-content: flex-start;
          align-items: center;
          padding-right: 30px;
          padding-top: 10px;
        }
        
        .switch-text {
          color: $primary-color;
          font-size: 16px;
          padding-left: 10px;
        }
        
        .switch-text-black {
          color: $black;
          font-size: 16px;
          padding-left: 10px;
        }

        .image-container {
          height: 96px;
          width: 96px;
          position: relative;
          border-radius: 50%;
          cursor: pointer;
          display: flex;
          justify-content: center;
          
          align-items: center;

          .avatar {
            width: 96px;
            height: 96px;
            align-self: center;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 50%;
            object-fit: cover;
          }

          .avatar-text {
            border-radius: 50%;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            font-size: 40px;
            font-weight: bold;
            width: 96px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 96px;
          }
        }

        .pen-icon-container {
          position: absolute;
          bottom: 0px;
          right: 3px;
          width: 34px;
          height: 34px;
          border-radius: 50%;
          background: #ffffff;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          display: flex;
          justify-content: center;
          align-items: center;

          .pen-icon {
            height: 18px;
            object-fit: contain;
          }
        }

        .text-left {
          font-size: 16px;
          font-weight: bold;
          width: 100%;
        }
      }

      hr {
        border: 1px solid #cccccc;
      }

      .right-column {
        display: flex;
        flex-direction: column;
        padding-left: 50px;
        width: 100%;
        height: 100%;
      }
    }
    .delete-container{
      width: 100%;
      padding-left: 32px;
      display: flex;

      .delete-button-mobile{
        align-self: flex-start;
        width: 280px;
        background-color: $red;
      }

      .delete-button{
        align-self: flex-start;
        width: 280px;
        background-color: $gray;
      }
  
      .delete-button:hover{
        background-color: $red;
      }  
    }

    .update-container{
      width: 100%;
      padding-right: 65px;
      display: flex;
      justify-content: flex-end;

      .update-button {
        width: 280px;
        height: 50px;
        font-size: 14px;
        background-color: $gray;
      }

      .update-button-mobile {
        width: 280px;
        height: 50px;
        font-size: 14px;
        background-color: $primary-color;
      }
  
      .update-button:hover{
        background-color: $primary-color;
      }
  
      .update-button:disabled{
        background-color: $gray;
      }
    }   

    .payment-button {
      background-color: $primary-color;
      border-radius: $border-radius;
      height: 50px;
      border: none;
      color: $white;
      font-weight: bold;
      cursor: pointer;
      width: 100%;
    }
    
    .payment-button:disabled {
      background-color: $gray;
    }
    
    .payment-button-container-loading{
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}



@media screen and (max-width: 1000px) {
  .popup-profile {
    overflow-y: auto;
    display: flex;
    position: fixed;
    width: 80%;
    height: 90%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: $white;
    border-radius: 8px;
    flex-direction: column;
  }

  .popup-profile .popup-column {
    height: min-content;
    display: block;
  }

  .popup-profile .popup-column .cancel-icon{
    margin-top: 10px;
    margin-left: 97%;
  }

  .popup-profile .popup-column .popup-row {
    flex-direction: column;
    align-items: center;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-top: 0px;
  }

  .popup-profile .popup-column .popup-row .left-column {
    padding-right: 0px;
    padding-bottom: 20px;
    width: 100%;
    height: min-content;
  }

  .popup-profile .popup-column .popup-row .right-column {
    padding-left: 0px;
    padding-top: 20px;
    width: 100%;
    height: min-content;
  }

  .popup-profile .popup-column .delete-container{
    padding-left: unset;
  }

  .popup-profile .popup-column .update-container{
    padding-right: unset;
  }

  .popup-profile .popup-column .delete-container .delete-button{
    width: 100%;
  }

  .popup-profile .popup-column .update-container .update-button {
    width: 100%;
    margin-left: unset;
  }

  .popup-profile .popup-column .delete-container .delete-button-mobile{
    width: 100%;
  }

  .popup-profile .popup-column .update-container .update-button-mobile {
    width: 100%;
    margin-left: unset;
  }

  .popup-profile .popup-column .update-container .update-button:disabled {
    width: 100%;
    margin-left: unset;
  }

  .popup-profile .popup-column hr {
    width: 100%;
    height: 1px;
  }

  .popup-profile .popup-column .logout{
    padding-bottom: 20px;
  }
}

@media screen and (max-height: 670px) {
  .popup-profile {
    overflow-y: auto;
    display: flex;
    position: fixed;
    width: 80%;
    height: 90%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: $white;
    border-radius: 8px;
    flex-direction: column;
  }

  .popup-profile .popup-column {
    height: min-content;
    display: block;
  }

  .popup-profile .popup-column .cancel-icon{
    margin-top: 10px;
    margin-left: 97%;
  }

  .popup-profile .popup-column .popup-row {
    flex-direction: column;
    align-items: center;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
  }

  .popup-profile .popup-column .popup-row .left-column {
    padding-right: 0px;
    padding-bottom: 20px;
    width: 100%;
    height: min-content;
  }

  .popup-profile .popup-column .popup-row .right-column {
    padding-left: 0px;
    padding-top: 20px;
    width: 100%;
    height: min-content;
  }

  .popup-profile .popup-column .update-button {
    width: 100%;
  }

  .popup-profile .popup-column hr {
    width: 100%;
    height: 1px;
  }

  .popup-profile .popup-column .delete-container{
    width: 100%;
    padding-left: unset;
    display: unset;
  }

  .popup-profile .popup-column .update-container{
    width: 100%;
    padding-right: unset;
    justify-content: unset;
    display: unset;
  }

  .popup-profile .popup-column .delete-container .delete-button{
    width: 100%;
    margin-left: unset;
  }

  .popup-profile .popup-column.update-container .update-button{
    margin-left: unset;
  }

  .popup-profile .popup-column .delete-container .delete-button-mobile{
    width: 100%;
    margin-left: unset;
  }

  .popup-profile .popup-column .update-container .update-button-mobile{
    margin-left: unset;
  }

  .popup-profile .popup-column .update-container .update-button:disabled{
    width: 100%;
    margin-left: unset;
  }

  .popup-profile .popup-column .update-button:hover{
    background-color: $primary-color;
  }

  .popup-profile .popup-column .payment-button{
    width: 100%;
    background-color: $gray;
  }

  .popup-profile .popup-column .payment-button:hover{
    background-color: $primary-color;
  }

  .popup-profile .popup-column .logout{
    padding-bottom: 20px;
  }
}

@media screen and (max-height: 800px){
  .popup-profile .header-text{
    font-size: 18px;
  }
}

@media screen and (min-height: 900px) {
  .popup-profile{
    height: min-content;
  }
}

@media screen and (max-width: 1000px) {
  .popup-profile{
    height: 90%;
  }
}