@import "../../../styles/scss-variable.scss";

.secondary-button-container {
  background-color: $white;
  border-radius: $border-radius;

  border: 1px solid $black;
  color: $black;
  font-weight: bold;
  cursor: pointer;
  height: 50px;
  text-align: center;
  width: 100%;
  white-space: nowrap;
}
