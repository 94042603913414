@import "../../../../../../styles/scss-variable.scss";

.sign-up-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: $default-form-width;

  .logo {
    height: 120px;
    width: 260px;
    object-fit: cover;
  }

  .input-content {
    display: flex;
    flex-direction: column;

    .sign-in-button {
      align-self: flex-end;
    }
  }
}

.input-row {
  justify-content: space-between;
}

.user-preview-container {
  height: 64px;
  width: auto;
  border-radius: $border-radius;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px 15px;
  font-weight: bold;
  .profile-image {
    height: 52px;
    width: 52px;
    border-radius: 50%;
  }

  .avatar-text {
    border-radius: 50%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 16px;
    font-weight: bold;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
  }
}

.action-row {
  justify-content: space-between;

  .back-container {
    cursor: pointer;

    .back-icon {
      height: 24px;
      object-fit: contain;
    }
  }
}

.image-upload-container {
  height: 160px;
  width: 160px;
  position: relative;
  border-radius: 50%;
  background-color: $gray;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  .avatar-text {
    cursor: pointer;
    border-radius: 50%;
    font-size: 55px;
    font-weight: bold;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .profile-image {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
  }

  .edit-image-icon-container {
    position: absolute;
    bottom: 20px;
    right: 0px;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;

    .pen-icon {
      height: 18px;
      object-fit: contain;
    }
  }
}

.continue-button-container {
  background-color: $primary-color;
  border-radius: $border-radius;
  height: 50px;
  border: none;
  color: $white;
  font-weight: bold;
  cursor: pointer;
  width: 100%;
}

.continue-button-container:disabled {
  background-color: $gray;
}

.continue-button-container-loading{
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.continue-button2-container {
  background-color: $primary-color;
  border-radius: $border-radius;
  height: 50px;
  border: none;
  color: $white;
  font-weight: bold;
  cursor: pointer;
  width: 100%;
}

.continue-button2-container:disabled {
  background-color: $gray;
}

.continue-button2-container-loading{
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
