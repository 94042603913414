@import "../../styles/scss-variable.scss";

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

*,
button,
input {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.full-screen {
  height: 100vh;
  width: 100vw;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.default-padding {
  padding: $default-padding;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bold {
  font-weight: bold;
}

.small-text {
  font-size: 16px;
}

.row {
  display: flex;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
}

.font-green{
  color: $primary-color;
}

.app-container {
  // background-color: $primary-color;
  .wave-img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: block;
    max-width: 50%;
    max-height: 100%;
    width: auto;
    height: auto;
  }
}
// Toast styling
.Toastify__toast {
  border-radius: 12px !important;

  .Toastify__toast-body {
    padding-left: 10px;
  }
}

.Toastify__toast--success {
  background: $primary-color !important;
}

.Toastify__toast--error {
  background: #de7474 !important;
}
