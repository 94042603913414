@import "../../../styles/scss-variable.scss";

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: $navbar-height;
  min-height: $navbar-height;
  width: 100%;
  padding-left: 30px;
  padding-right: 20px;
  overflow-x: auto;
  background: linear-gradient(180deg, #F3FFF9 0%, rgba(243, 255, 249, 0) 100%);

  .header {
    color: $primary-color;
    font-size: 18px;
    font-weight: bold;
  }

  .green-link {
    color: $primary-color;
    margin-right: 30px;
    font-size: 14px;
    cursor: pointer;
  }

  .link-profile-row{
    display: flex;

  .right-side {
    display: flex;
    align-items: center;

    @media screen and (min-width: 700px) {
      .link {
        color: $primary-color;
        margin-right: 30px;
        font-size: 14px;
        cursor: pointer;
      }
    }

      .subscribe-button{
        background-color: $primary-color;
        color: $white;
        width: 100px;
        height: 38px;
      }
  }

  .avatar-container {
    border-radius: 50%;

    img {
      width: 40px;
      object-fit: cover;
      height: 40px;
      cursor: pointer;
      border-radius: 50%;
    }

    .avatar {
      width: 40px;
      height: 40px;
      cursor: pointer;
      border-radius: 50%;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .avatar-text {
      background-color: $white;
      cursor: pointer;
      border-radius: 50%;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      font-size: 18px;
      font-weight: bold;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
    }
  }
  }
}

.dropdown-menu {
  border-radius: $border-radius;
  margin-top: 45px;

  .menu-icon {
    height: 28px;
    width: 40px;
    object-fit: contain;
  }
}

.link-row{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 10px;
}

@media screen and (max-width: 700px) {
  .navbar .link-profile-row .right-side .link{
    color: $primary-color;
    margin-right: 30px;
    font-size: 14px;
    cursor: pointer;
  }

  .navbar .link-profile-row .right-side{
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .navbar {
    justify-content: flex-end;
    max-width: 100%;
    padding-right: unset;

    .green-link {
      margin-right: 5px;
    }
  }

}

@media screen and (max-width: 300px) {
  .navbar .link-profile-row .right-side .link{
    color: $primary-color;
    margin: 1px;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 14px;
    cursor: pointer;
  }

  .link-row{
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 345px) {
  .navbar{
    overflow-x: auto;
    overflow-y: hidden;
    padding-left: unset;
    padding-right: unset;
    justify-content: flex-end;
  }

  .navbar .link-profile-row {
    width: 100%;
  }
}