@import "/src/styles/scss-variable.scss";

.subscribe-container{
    margin-left: auto;
    display: flex;
    flex-direction: column;

    .subscribe-text{
        font-size: 14px;
    }

    .subscribe-link{
        color: $primary-color;
        font-size: 14px;
        margin-left: auto;
        cursor: pointer;
    }
}

.bounce-loader{
    padding-right: 50px;
    padding-bottom: 20px;
}