@import "/src/styles/scss-variable.scss";

.popup-rules{
    overflow: auto;
    display: flex;
    position: fixed;
    width: 50%;
    height: 65%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: $white;
    border-radius: 8px;

    .popup-column{
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;

        .popup-header{
            padding-top: 10px;
            padding-left: 10px;
            padding-right: 10px;
            font-size: 24px;
            color: $primary-color;
        }

        .header-subtitle{
            padding-left: 10px;
        }

        .header-subtitle2{
            padding-left: 0px;
        }

        hr {
            height: 0px;
            width: 96%;
            border: 1px solid #CCCCCC;
            margin: 1em 0;
            padding: 0;
        }

        .left-align-table{
            overflow-y:auto;
            width: 100%;
            height: 100%;
            padding-left: 5%;
            padding-right: 5%;
            column-count: 2;
            column-rule:2px solid;
            .big-title{
                display: flex;
                height: 105px;
                font-size: 36px;
                line-height: 46.41px;
                font-weight: bold;
                align-items: center;
            }

            .subtitle{
                top: 285px;
                display: flex;
                height: 71px;
                font-size: 24px;
                font-weight: bold;
                line-height: 30.94px;
                align-items: center;
            }

            .text{  
                font-size: 16px;
                line-height: 21.2px;

            }
            #right-box{
                display:inline-block;
                padding-left: 10px;
            }
            #left-box{
                display:inline-block;
            }

        }
        .button-row{
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            width: 100%;
            padding-bottom: 30px;
            padding-right: 30px;

            .accept-button{
                width: 302px;
                height: 50px;
            }
    
            .cancel-button{
                width: 102px;
                background-color: $gray;
            }
        }        
    }
}

@media screen and (max-width: 900px){
    .popup-rules .popup-column .left-align-column .text{
        font-size: 90%;
    }

    .popup-rules .popup-column .left-align-column{
        padding-left: 10px;
        padding-right: 10px;
    }

    .popup-rules .popup-column .left-align-column .big-title{
        font-size: 100%;
        height: 10%
    }

    .popup-rules .popup-column .left-align-column .subtitle{
        font-size: 100%;
        height: 10%
    }

    .popup-rules .popup-column .left-align-column .header-subtitle2{
        font-size: 95%;
    }

    .popup-rules .popup-column .left-align-column {
        height: 150px;
        width: 400px;
    }
}

@media screen and (max-width: 600px){
    .popup-rules .popup-column .left-align-column .text{
        font-size: 60%;
    }

    .popup-rules .popup-column .left-align-column .big-title{
        font-size: 90%;
        height: 10%
    }

    .popup-rules .popup-column .left-align-column .subtitle{
        font-size: 90%;
        height: 10%
    }

    .popup-rules .popup-column .left-align-column .header-subtitle2{
        font-size: 70%;
        margin-bottom: 10px;
    }

    .popup-rules .popup-column .button-row .accept-button{
        width: 100%;
    }

    .popup-rules .popup-column .left-align-column {
        height: 30%;
        width: 70%;
    }

    .popup-rules .popup-column .button-row{
        padding-right: unset;
    }
}

@media screen and (max-width: 400){
    .popup-rules .popup-column .left-align-column{
        height: 70px;
        width: 100%;
    }
}
