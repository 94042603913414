@import "../../../../../../styles/scss-variable.scss";

.sign-in-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: $default-form-width;

  .logo {
    height: 120px;
    min-width: 260px;
    object-fit: cover;
  }

  .input-content {
    display: flex;
    flex-direction: column;

    .sign-in-button {
      align-self: flex-end;
    }
  }

  .forgot-password-container {
    cursor: pointer;
  }
}

@media screen and (max-width: 550px) {
  .sign-in-form{
    margin-left: 10%;
    margin-right: 10%;
  }
}
