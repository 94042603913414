@import "../../../../../../styles/scss-variable.scss";

.sign-up-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: $default-form-width;

  .logo {
    height: 120px;
    width: 260px;
    object-fit: cover;
  }

  .input-content {
    display: flex;
    flex-direction: column;
    
    .sign-in-button {
      align-self: flex-end;
    }
  }
}

.terms-link {
  color: $primary-color;
  font-size: 15px;
  cursor: pointer;
  margin-left: 4px;
  margin-bottom: 2px;
}

.switch-style-agb {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;

  .switch-text {
    color: $primary-color;
    font-size: 16px;
    cursor: pointer;
    padding-left: 4px;
    padding-right: 4px;
  }
}

.register-text {
  color: $primary-color;
  font-size: 32px;
  text-decoration: underline;
}  
