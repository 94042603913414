@import "/src/styles/scss-variable.scss";
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Space+Grotesk:wght@300..700&display=swap');
.popup-subscription{
    overflow: auto;
    display: flex;
    position: fixed;
    width: 60%;
    height: 80%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border-radius: 8px;
    align-items: center;

    .cross-circle-full {
        border-radius: 50%;
        width: 25px;
        height: 25px;
        padding: 0px;
        background: $white;
        text-align: center;
        align-content: center;
        color: $red;
        font-family:"Nunito";
        font-size: 22px;
        line-height: 100%;
        font-weight: 650;
        position: fixed;
        padding: 3px;
        margin-left:58%;
        margin-bottom:20%;
        border: none;
        cursor: pointer;
    }

    .cross-circle-partial {
        border-radius: 50%;
        width: 25px;
        height: 25px;
        padding: 0px;
        background: $white;
        text-align: center;
        align-content: center;
        color: $red;
        font-family:"Nunito";
        font-size: 22px;
        line-height: 100%;
        font-weight: 650;
        position: fixed;
        padding: 3px;
        margin-left:38%;
        margin-bottom:20%;
        border: none;
        cursor: pointer;
    }
    .popup-container{
        position: static;
        align-items: center;
        padding-right: 10px;
        padding-top: 20px;
        background-color: $white;
        border-radius: 10px;
        width:26%;
        height:45%;
        margin:0 auto;
        left:0;
        right:0;
        padding-bottom: 35px;
        .currency-text{
            display: inline;
            margin-right:5px;
            font-size: 112%;
        }

        .duration-text{
            display: inline
        }

        .price-text{
            display: inline;
            font-size: 300%;
        }

        .description-text{
            font-weight:500;
            font-size: 125%;
            padding-bottom: 15px;
            padding-top:5px;
        }

        .benefit-text{
            display: inline-block;
            padding-left: 3px;
            font-size:112%;
            margin-top:10px;
        }
        .tick-circle {
            border-radius: 50%;
            width: 20px;
            height: 20px;
            padding: 0px;
            background: $gray;
            text-align: center;
            display: inline-block;
            color: $primary-color;
        }

        .popup-text{
            height : 80%;
            padding-left: 30px;
        }
        .supported-browsers-image {
            max-width: 550px;
            aspect-ratio: auto;
        }
        .button-row{
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            width: 100%;
            padding-bottom: 30px;
            padding-right: 30px;
            padding-left: 30px;
            height :20%;
            margin: 0 auto;

            .accept-button{
                width: 302px;
                height: 50px;
                font-weight: 24px;
            }
    
            .cancel-button{
                width: 102px;
                background-color: $gray;
            }
        }        
    }
}

.popup-out-container{
    display:block;
    background-color: $white;
    margin:0 auto;
    border-radius: 10px;
    padding: 10px;
    width: 576px; 
    position: fixed;
    left:0;
    right:0;
    float: none;
    top:17%;

    .popup-header{
        font-size: 24px;
        color: $primary-color;
        text-align: center;
    }

    .popup-subheader{
        text-align: center;
        font-size: 18px;
    }
}

@media screen and (max-width: 900px){
    .popup-subscription .popup-container .text .currency-text .description-text .price-text .duration-text{
        font-size: 90%;
    }

    .popup-subscription .popup-container .popup-out-container{
        padding-left: 10px;
        padding-right: 10px;
    }

    .popup-subscription .popup-container .popup-out-container .big-title{
        font-size: 100%;
        height: 10%
    }

    .popup-subscription .popup-container .popup-out-container .subtitle{
        font-size: 100%;
        height: 10%
    }

    .popup-subscription .popup-container .popup-out-container .header-subtitle2{
        font-size: 95%;
    }

    .popup-subscription .popup-container .popup-out-container .supported-browsers-image{
        height: 150px;
        width: 400px;
    }
    .cross-circle-full{
        margin-left: 100%;
    }
}

@media screen and (max-width: 600px){
    .popup-subscription .popup-container .text .currency-text .description-text .price-text .duration-text{
        font-size: 60%;
    }

    .popup-subscription .popup-container .popup-out-container {
        font-size: 90%;
        height: 10%
    }

    .popup-subscription .popup-container .popup-out-container {
        font-size: 90%;
        height: 10%
    }

    .popup-subscription .popup-container .popup-out-container {
        font-size: 70%;
        margin-bottom: 10px;
    }

    .popup-subscription .popup-container .button-row .accept-button{
        width: 100%;
    }

    .popup-subscription .popup-container .popup-out-container {
        height: 30%;
        width: 70%;
    }

    .popup-subscription .popup-container .button-row{
        padding-right: unset;
    }
}

@media screen and (max-width: 400){
    .popup-subscription .popup-container .popup-out-container {
        height: 70px;
        width: 200px;
    }
}
