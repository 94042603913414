@import "/src/styles/scss-variable.scss";

.popup-referal{
    overflow: auto;
    display: flex;
    position: fixed;
    width: 35%;
    height: min-content;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: $white;
    border-radius: 8px;

    .popup-column{
        display: flex;
        flex: 1;
        flex-direction: column;
        max-width: 100%;
        align-items: center;
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 30px;
        align-content: center;

        .referral-image{
            width: 400px;
            border-radius: 10px;
        }   

        .text{
            text-align: left;
            align-self: flex-start;
            overflow: wrap;
        }

        .link{
            cursor: pointer;
            align-self: flex-start;
        }
    }
}

.sign-up-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: $default-form-width;
    position: fixed;
    width: 35%;
    height: min-content;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: $white;
    border-radius: 8px;
    padding: 30px;

    .input-content {
      display: flex;
      flex-direction: column;
        
      .refer-button {
        align-self: flex-end;
      }
    }
  }

  .button-row{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    padding-bottom: 30px;
    padding-top: 20px;
    align-self: center;
    align-content: center;

    .invite-button{
        width: 250px;
        margin: 0 auto;
    }

    .close-button{
        width: 250px;
        height: 50px;
        background-color: $gray;
        margin: 0 auto
    }
}   
@media screen and (max-width: 800px){
    .popup-referal .popup-column .referral-image {
        width: 200px;
    }
}

@media screen and (max-width: 600px){
    .popup-referal  {
        width: 80%;
    }
}

@media screen and (max-width: 460px){
    .popup-referal .popup-column .text  {
        font-size: 12px;
    }
}

@media screen and (max-width: 350px){
    .popup-referal .popup-column .referral-image {
        width: 150px;
    }

    .popup-referal .popup-column {
        align-items: flex-start;
        overflow: auto;
    }
}