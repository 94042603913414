@import "../../../styles/scss-variable.scss";

.progress-circle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: $gray;
  margin-right: 5px;
}

.active {
  background-color: $primary-color !important;
}
