@import "../../../../styles/scss-variable.scss";

  .video-chat-container {
    height: calc(100vh - 60px);
    position: relative;

    .countdown-component {
      position: absolute;
      top: 20px;
      left: 20px;
    }

    .countdown-component-chat {
      position: absolute;
      top: 20px;
      left: 350px;
    }

    .youttube-container {
      height: calc(100vh - 60px);
    }
}