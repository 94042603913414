@import "../../../styles/scss-variable.scss";

.primary-button-container {
  background-color: $primary-color;
  border-radius: $border-radius;
  height: 50px;
  border: none;
  color: $white;
  font-weight: bold;
  cursor: pointer;
  width: 100%;
}

.buttonText {
  color: white;
  font-weight: 100;
}

.primary-button-container:disabled {
  background-color: $gray;
}

.primary-button-container-loading{
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
