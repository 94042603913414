@import "/src/styles/scss-variable.scss";

.popup-report{
    display: flex;
    position: fixed;
    flex-direction: column;
    overflow-y: auto;
    width: 35%;
    height: 45%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: $white;
    border-radius: 8px;
    align-items: center;

    .cancel-icon{
        width: 12.33;
        height: 12.33;
        align-self: flex-end;
        cursor: pointer;
        padding-right: 39.84px;
        padding-top: 39.84px;
    }

    .column-report{
        display: flex;
        flex-direction: column;
        padding-right: 75px;
        padding-left: 75px;
        align-items: center;
    }
}

@media screen and (max-width: 1000px){
    .popup-report{
        height: min-content;
        width: min-content;
    }
    .popup-report .column-report{
        padding-left: 10%;
        padding-right: 10%;
        padding-bottom: 10%;
    }

    .popup-report .cancel-icon{
        padding-top: 10%;
        padding-right: 10%;
    }
}
