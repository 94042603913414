@import "/src/styles/scss-variable.scss";

.popup-congrats{
    display: flex;
    position: fixed;
    width: 30%;
    height: min-content;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: $white;
    border-radius: 8px;
    justify-content: space-around;

    .popup-column{
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-right: 30px;
        padding-top: 39.83px;
        padding-left: 30px;
        padding-bottom: 20px;
        align-items: flex-start;

        .cancel-icon{
            width: 12.33px;
            height: 12.33px;
            right: 5px;
            align-self: flex-end;
            cursor: pointer;
        }

        .ballon-icon{
            width: 45px;
            height: 43px;
        }

        .finished-button{
            background-color: $gray;
            align-self: flex-end;
        }
    }
}

@media screen and (max-width: 600px){
    .popup-congrats .popup-column{
        overflow-y: auto;
        padding-left: 10px;
    }

    .responsive-text{
        font-size: 4vw;;
    }
}

@media screen and (max-width: 1250px) {
    .popup-congrats{
        width: 60%;
    }
}