@import "../../../styles/scss-variable.scss";
.spacer1 {
  width: 80px;
  min-width: 80px;
  background-color: white;
  height: 75px;
}

.switch-style {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  padding-right: 30px;
  padding-top: 10px;
}

.switch-text {
  font-size: 14px;
  padding-right: 10px;
}

.spacer2 {
  width: 80px;
  min-width: 80px;
  background-color: white;
  height: 75px;
  margin-top: -1px;
}

.calendar-date-special {
  position: absolute;
  bottom: -8px;
  left: 10px;
  font-weight: bold;
}

.session-count-container{
  margin-left: auto;
  display: flex;
  flex-direction: row;

  .session-count-text{
      font-size: 14px;
      margin-left: 5px;
  }
}

.calendar-component {
  margin-top: 10px;
  margin-right: 50px;
  margin-bottom: 50px;
  margin-left: 30px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 50px;
  padding-top: 20px;
  box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.09);
  border-radius: 12px;

  .calendar-scrollable {
    display: flex;

    .calendar-lines {
      width: 100%;

      .calendar-line {
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        width: 100%;

        .calendar-date {
          position: absolute;
          left: -70px;
          top: -10px;
          z-index: 5;
          font-weight: bold;
          color: $black;
        }
      }
    }
  }
  .headlz {
    display: flex;
    flex-direction: row;
    width: 100%;
    z-index: 99;
  }

  .scroll-container {
    overflow: hidden;
    position: relative;
    height: calc(100vh / 1.4);
    overflow-y: scroll;
  }

  .calendar-header {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 30px;
    margin-right: 10px;

    .right {
      margin-left: auto;
      padding-bottom: 20px;
    }

    .left {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .today {
        font-size: 18px;
        padding: 10px 25px;
        border-radius: $border-radius;
        color: $black;
        border: 1px solid $black;
        line-height: 22px;
        cursor: pointer;
      }

      .favourites {
        font-size: 18px;
        margin-bottom:20px;
        color: $primary-color;
        cursor: pointer;
      }

      .btn-container {
        cursor: pointer;
        display: flex;
        margin: 0px 30px;
        margin-right: 15px;

        img {
          width: 18px;
          height: 18px;
          object-fit: contain;
        }

        img:last-child {
          margin-right: 10px;
        }
      }

      .month {
        font-size: 36px;
        color: $black;
      }
    }
  }

  .calendar-cell-header {
    display: flex;
    width: 100%;
    position: sticky;
    top: 0px;
    z-index: 100;
  }
}

.info-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10px;
  padding-top: 5px;

  .guidelines-link{
    color: $primary-color;
    font-size: 16px;
    margin-top: 5px;
    cursor: pointer;
}

  .info-text {
    font-size: 12px;
    cursor: pointer;
  }

  .small-text{
    font-size: 12px;
}
}

@media screen and (max-width: 700px) {
  .calendar-component{
    margin: 0px;
    padding-top: 20px;
  }

  .calendar-cell-header {
    width: 67%;
  }

  .right {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 850px) {
  .spacer1 {
    width: 10px;
    min-width: 0px;
  }

  .month {
    font-size: 24px;
  }

  .spacer2 {
    width: 80px;
    min-width: 80px;
    background-color: white;
    height: 75px;
  }
}

@media screen and (max-width: 350px) {
  .calendar-component .calendar-header .left .btn-container {
    flex-direction: row;
    margin: 10px;
    margin-right: 0px;

    img {
      width: 18px;
      height: 18px;
      object-fit: contain;
    }

    img:last-child {
      margin-right: 10px;
    }
  }
}

@media screen and (max-height: 930px) {
  .calendar-component .scroll-container{
    height: calc(100vh / 1.5);
  }
}

@media screen and (max-height: 800px) {
  .calendar-component .scroll-container{
    height: calc(100vh / 1.7);
  }
}

@media screen and (max-height: 660px) {
  .calendar-component .scroll-container{
    height: calc(100vh / 1.9);
  }
}

@media screen and (max-height: 570px) {
  .calendar-component .scroll-container{
    height: calc(100vh / 2);
  }
}

@media screen and (max-width: 700px) {
  .calendar-component .scroll-container{
    height: 100%;
    overflow: hidden;
  }  
}

@media screen and (max-width: 430px) {
  .calendar-component .calendar-header .left .month{
    font-size: 24px;
  }  
}

@media screen and (max-width: 310px) {
  .calendar-component{
    padding-left: 5px;
  }  
}