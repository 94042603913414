@import "/src/styles/scss-variable.scss";

.popup-attendance {
  display: flex;
  position: fixed;
  min-width: 45%;
  width: fit-content;
  max-width: 70%; 
  height: min-content;
  left: 0;
  right: 0;
  bottom: 0;
  top:0;
  margin: auto;
  background-color: $white;
  border-radius: 8px;
  justify-content: space-around;

  .popup-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 30px;
    justify-content: space-between;

    .top-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .avatar {
    width: 96px;
    height: 96px;
    border-radius: 50%;
    object-fit: cover;
  }

  .avatar-text {
    border-radius: 50%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 40px;
    font-weight: bold;
    width: 96px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 96px;
  }

  .user-line{
    height:min-content;
    margin:0 auto;
    align-items: center;
    display: flex;
    .username {
      font-weight: 600;
      font-size: 16px;
      margin-left: auto;
      margin-right:0;
      width:min-content;
    }
  
    .star{
      cursor: pointer;
      border: none;
      background-color: white;
      margin-right: auto;
      margin-left:0;
    }
  
  }
  .session-text {
    font-style: italic;
    font-size: 14px;
    font-weight: normal;
    color: $gray;
  }

  .task-text-mobile{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    align-self: center;
  }
  
  .header {
    font-size: 16px;
  }

  .task-text {
    font-size: 15px;
  }

  .button-row {
    display: flex;
    flex-direction: row;
    width: 100%;

    .decline-button {
      background: $gray;
    }
  }
}

.attend-button-container {
  background-color: $primary-color;
  border-radius: $border-radius;
  height: 50px;
  border: none;
  color: $white;
  font-weight: bold;
  cursor: pointer;
  width: 100%;
}

.attend-button-container:disabled {
  background-color: $gray;
}

.attend-button-container-loading{
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1000px) {
  .popup-attendance .popup-column {
    overflow-y: auto;
  }

  .popup-attendance {
    height: min-content;
  }
}

@media screen and (max-width: 500px) {
  .popup-attendance {
    width: 65%;
  }
}
